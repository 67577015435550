/* eslint-disable camelcase */
import { createReducer } from "reduxsauce";
import { ConfigurationTypes as Types } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.configuration;

export const getConfigurationFilesFailure = state => ({
  ...state,
});
export const getConfigurationFilesRequest = state => ({
  ...state,
});
export const getConfigurationFilesSuccess = (state, action) => {
  return {
    ...state,
    files: action.files.files,
    connections_count: action.files.connections_count,
  };
};

export const getDownloadFormatFileMappingFailure = state => ({
  ...state,
});
export const getDownloadFormatFileMappingRequest = state => ({
  ...state,
});
export const getDownloadFormatFileMappingSuccess = (
  state,
  action,
) => {
  return {
    ...state,
    download_format_mapping: action.mapping,
  };
};

export const saveDownloadFormatFileMappingFailure = state => ({
  ...state,
  isMappingUpdateRunning: false,
});
export const saveDownloadFormatFileMappingRequest = state => ({
  ...state,
  isMappingUpdateRunning: true,
});
export const saveDownloadFormatFileMappingSuccess = state => ({
  ...state,
  isMappingUpdateRunning: false,
});

export const getMappedColumnsFailure = state => ({
  ...state,
});
export const getMappedColumnsRequest = state => ({
  ...state,
});
export const getMappedColumnsSuccess = (state, action) => {
  return {
    ...state,
    mapped_columns: action.columns,
  };
};

export const HANDLERS = {
  [Types.GET_CONFIGURATION_FILES_FAILURE]:
    getConfigurationFilesFailure,
  [Types.GET_CONFIGURATION_FILES_REQUEST]:
    getConfigurationFilesRequest,
  [Types.GET_CONFIGURATION_FILES_SUCCESS]:
    getConfigurationFilesSuccess,

  [Types.GET_DOWNLOAD_FORMAT_FILE_MAPPING_FAILURE]:
    getDownloadFormatFileMappingFailure,
  [Types.GET_DOWNLOAD_FORMAT_FILE_MAPPING_REQUEST]:
    getDownloadFormatFileMappingRequest,
  [Types.GET_DOWNLOAD_FORMAT_FILE_MAPPING_SUCCESS]:
    getDownloadFormatFileMappingSuccess,

  [Types.GET_MAPPED_COLUMNS_FAILURE]: getMappedColumnsFailure,
  [Types.GET_MAPPED_COLUMNS_REQUEST]: getMappedColumnsRequest,
  [Types.GET_MAPPED_COLUMNS_SUCCESS]: getMappedColumnsSuccess,

  [Types.SAVE_DOWNLOAD_FORMAT_FILE_MAPPING_FAILURE]:
    saveDownloadFormatFileMappingFailure,
  [Types.SAVE_DOWNLOAD_FORMAT_FILE_MAPPING_REQUEST]:
    saveDownloadFormatFileMappingRequest,
  [Types.SAVE_DOWNLOAD_FORMAT_FILE_MAPPING_SUCCESS]:
    saveDownloadFormatFileMappingSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
