import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getConfigurationFilesFailure: null,
  getConfigurationFilesRequest: null,
  getConfigurationFilesSuccess: ["files"],

  getDownloadFormatFileMappingFailure: null,
  getDownloadFormatFileMappingRequest: null,
  getDownloadFormatFileMappingSuccess: ["mapping"],

  saveDownloadFormatFileMappingFailure: null,
  saveDownloadFormatFileMappingRequest: ["mapping_data"],
  saveDownloadFormatFileMappingSuccess: null,

  getMappedColumnsFailure: null,
  getMappedColumnsRequest: null,
  getMappedColumnsSuccess: ["columns"],
});

export const ConfigurationTypes = Types;
export const ConfigurationCreators = Creators;
