import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getConfigurationFiles(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/configuration_files`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getDownloadFormatMapping(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/partner_mappings/configuration_mappings`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function saveDownloadFormatMapping(authToken, mappingData) {
  const data = {
    partner_mapping: {
      mapping_columns: mappingData,
    },
  };
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/partner_mappings/update_configuration_mappings`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getMappedColumns(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/partner_mappings/mapped_columns`,
    headers: {
      authentication_token: authToken,
    },
  });
}
